import { render, staticRenderFns } from "./consumer.vue?vue&type=template&id=73c3738e"
import script from "./consumer.vue?vue&type=script&lang=js"
export * from "./consumer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/usr/src/eventum-portal/components/form/FormInput.vue').default,FormSelect: require('/usr/src/eventum-portal/components/form/FormSelect.vue').default,IconDelete: require('/usr/src/eventum-portal/components/icon/IconDelete.vue').default,ProductDeliveryDate: require('/usr/src/eventum-portal/components/product/ProductDeliveryDate.vue').default})
